import { type ReactNode, useEffect, useState, createContext, useRef } from 'react'
import { ulid } from 'ulidx'

export type PopupMessageType = 'success' | 'error'

export type PopupMessageInfo = {
  text: string
  type?: PopupMessageType
}

export type PopupMessageStateInfo = PopupMessageInfo & {
  // メッセージを一意に識別するためのID
  id: string
}

export const PopupMessageContext = createContext<PopupMessageStateInfo[]>([])

export const SetPopupMessageContext = createContext<(info: PopupMessageInfo) => void>(() => undefined)

export const PopupMessageProvider = ({ children }: { children: ReactNode }) => {
  const [popupMessage, setPopupMessage] = useState<PopupMessageStateInfo[]>([])
  const timeoutsRef = useRef<{ [key: string]: NodeJS.Timeout }>({})

  const removePopupMessage = (id: string) => {
    if (timeoutsRef.current[id]) {
      clearTimeout(timeoutsRef.current[id])
      delete timeoutsRef.current[id]
    }

    setPopupMessage((prevMessages) => {
      return prevMessages.filter((message) => {
        return message.id !== id
      })
    })
  }

  useEffect(() => {
    return () => {
      // コンポーネントのアンマウント時に全てのタイマーをクリア
      // eslint-disable-next-line react-hooks/exhaustive-deps
      Object.values(timeoutsRef.current).forEach((timeout) => clearTimeout(timeout))
    }
  }, [])

  return (
    <PopupMessageContext.Provider value={popupMessage}>
      <SetPopupMessageContext.Provider
        value={(info) => {
          const newMessage = {
            ...info,
            id: ulid(),
          }

          setPopupMessage((prevMessages) => {
            // メッセージを一定時間後に削除するタイマーを設定
            timeoutsRef.current[newMessage.id] = setTimeout(() => {
              removePopupMessage(newMessage.id)
            }, 3000)

            return [...prevMessages, newMessage]
          })
        }}
      >
        {children}
      </SetPopupMessageContext.Provider>
    </PopupMessageContext.Provider>
  )
}
